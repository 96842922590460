.layout {
	display: flex;
	flex-direction: column;
}

.card {
	margin-bottom: 1em;
}

.card-header {
	background: var(--color-theme-offwhite);
}

.card-header > *:first-child {
	background: var(--color-theme-offwhite);
	color: var(--color-theme-primary);
	cursor: pointer;
}

.card-header,
.list-group-item {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.noData {
	font-size: 16px;
	margin: 18px;
}
