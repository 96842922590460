.cell {
	padding: 0;
	position: relative;
}
.actionsContainer {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
}

.action {
	background: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
	padding: 10px;
	flex-shrink: 0;
	flex-grow: 1;
}
.action > * {
	font-size: 1.2em;
}
.action:not(.disabled):hover {
	background: rgba(0, 0, 0, 0.05);
}
.disabled {
	color: var(--color-theme-gray);
}
