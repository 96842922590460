.assetThumbnail {
	height: 15vh;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.container {
	--tabs-panel-padding: 0;
}
.assetInfo {
	padding: 10px;
}
.sectionLine {
	border-color: rgba(255, 255, 255, 0.5);
	width: 100%;
	margin: 0;
}
.statusIcons {
	display: inline-flex;
	margin-inline-start: 0.2em;
}
.assetTitle,
.assetSubHeader {
	margin-block-start: 0.2em;
	margin-block-end: 0.2em;
}

.layoutSideDrawer {
	max-width: calc(100% - 400px);
}
