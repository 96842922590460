.rental-search {
	md-input-container {
		padding: 2px 2px 0;
	}

	.search-results {
		padding-left: 25px;
	}
}

.rental-list {
	.status-pill {
		&.pending {
			color: var(--color-theme-white);
			background-color: $color-brand-orange;
		}
		&.on-rent {
			color: var(--color-theme-white);
			background-color: $color-health-positive;
		}
		&.scheduled {
			color: var(--color-theme-white);
			background-color: $color-default-text;
		}
		&.completed {
			color: var(--color-theme-white);
			background-color: $color-brand-blue;
		}
	}
}
