.white {
	color: var(--color-theme-white);
}

.grey {
	color: var(--color-theme-default);
}

.color-positive {
	color: var(--color-theme-positive);
}

.app-wrapper {
	height: 100%;
}

.page-container {
	height: 100%;
}

.app-content,
.assets {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	position: relative;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	will-change: overflow;
	width: 100%;
	overflow-y: auto;
}
.app-content .map,
.assets .map {
	width: 100%;
	display: flex;
}

.app-content {
	height: 100%;
}

.section-padding {
	padding: 0 25px;
}

.assets {
	height: 100%;
}

.md-open-menu-container {
	z-index: 99999;
	top: 59px !important;
}
.md-open-menu-container i {
	min-width: 20px;
}

md-menu-item .md-button p {
	line-height: 48px;
}

.panel {
	background: #f5f5f5;
	width: 100%;
	overflow-y: auto;
	height: 100%;
}
.panel .tabs {
	border-bottom: 1px solid #e0e0e0;
	background-color: #fafafa;
	padding-right: 19px;
	text-align: right;
}
.panel .tabs.tabs-left {
	text-align: left;
}
.panel .tabs .tab {
	text-align: center;
	display: inline-block;
	cursor: pointer;
	margin-right: -4px;
	border-bottom: 5px solid transparent;
	line-height: 24px;
}
.panel .tabs .tab.disabled-tab {
	cursor: not-allowed;
}
.panel .tabs .tab.disabled-tab h4 {
	color: #bdbdbd;
}
.panel .tabs .tab.disabled-tab:hover h4 {
	color: #bdbdbd;
}
.panel .tabs .tab h4 {
	padding: 12px 25px 7px;
	font-weight: 500;
	font-size: 16px;
	display: inline-block;
	margin: 0;
	border-bottom: 5px solid transparent;
}
.panel .tabs .tab:hover h4 {
	color: #212121;
}
.panel .tabs .tab.disabled {
	cursor: not-allowed;
}
.panel .tabs .tab.disabled h4 {
	color: #9e9e9e;
}
.panel .tabs .tab.selected {
	border-bottom: 5px solid var(--color-theme-primary);
}
.panel .tabs .tab.selected h4 {
	color: #424242;
}
.panel .tabs .tab .icon-problem {
	font-size: 16px;
	padding-top: 5px;
	float: right;
	margin-left: -16px;
}
.panel .tabs .exit.md-button {
	padding: 15px 15px 14px;
	margin: 0 15px 0 0;
	float: left;
}
.panel .tab-content {
	padding-top: 30px;
}
.panel i.exit {
	font-size: 37px;
}
.panel .exit {
	cursor: pointer;
}
.panel .exit.md-button {
	padding: 0 15px;
	margin: 0 0 15px;
	min-width: 0;
}
.panel .exit.md-button.icon {
	line-height: 0;
}
.panel .exit.md-button.icon i {
	top: 0;
}
.panel .exit.md-button i {
	font-size: 24px;
	vertical-align: middle;
	position: relative;
	top: -1px;
}
.panel .exit.md-button .fa-long-arrow-left {
	margin-right: 5px;
}
.panel .vehicle-diagnostic-entry {
	margin-top: 20px;
}
.panel .vehicle-diagnostic-entry h3 {
	margin: 0 0 5px;
}
.panel .vehicle-diagnostic-entry h4 {
	margin: 5px 0 0;
	font-weight: 500;
}

md-menu-content {
	border-radius: 2px;
}

md-toolbar.no-padding {
	margin: -30px -15px 15px;
	width: calc(100% + 30px);
}

.md-card-content {
	padding: 0 15px 15px;
}

.datepicker-container {
	font-size: 14px;
	color: #212121;
}

.datepicker {
	margin-top: 15px;
}

.datepicker .md-datepicker-calendar-icon {
	margin: 0;
}

.md-datepicker-input-container.md-datepicker-focused {
	border-color: var(--color-theme-primary);
}

.md-datepicker-open .md-datepicker-calendar-icon {
	fill: var(--color-theme-primary);
}

.md-datepicker-input-mask {
	width: 308px;
}

md-toolbar.closeable {
	position: absolute;
	top: 0;
	min-height: 54px;
	max-height: 54px;
}
md-toolbar.closeable h3 {
	width: 100%;
}

.md-button {
	font-weight: 600;
}

.list-title {
	padding: 15px 0 5px;
	margin: 0 25px;
	border-bottom: 2px solid #e0e0e0;
}
.list-title h2 {
	margin-left: 5px;
	overflow: hidden;
	display: inline-block;
	vertical-align: top;
}
.list-title h2 .back-button {
	margin: -7px 0 -13px -15px;
	min-width: auto;
}
.list-title h2 span {
	font-weight: 400;
	color: #757575;
}
.list-title .title-search {
	padding: 11px 0 0 30px;
}
.list-title .title-search i.material-icons {
	position: absolute;
	margin-left: -30px;
	margin-top: 4px;
	transition: color 0.2s linear;
}
.list-title .title-search input {
	transition: border-color 0.2s linear;
}

.list-subtitle {
	margin: 5px 0;
	display: inline-block;
	font-size: 16px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
	border-color: var(--color-theme-primary);
}

md-input-container:not(.md-input-invalid).md-input-focused i.material-icons {
	color: var(--color-theme-primary);
}

.padded-list {
	padding: 50px 0;
}

md-list.no-padding {
	padding: 0;
}

.utilization md-datepicker {
	background-color: transparent;
}

.utilization img.utilization-hours {
	width: 100%;
	margin: 15px 0 -10px;
}
@media (min-width: 1200px) {
	.utilization img.utilization-hours.desktop {
		display: block;
	}
	.utilization img.utilization-hours.tablet {
		display: none;
	}
}
@media (max-width: 1200px) {
	.utilization img.utilization-hours.desktop {
		display: none;
	}
	.utilization img.utilization-hours.tablet {
		display: block;
	}
}

.utilization .asset-usage {
	width: calc(100% - 30px);
	padding: 0 15px;
}

.utilization .trip .trip-details {
	padding-bottom: 25px;
}
.utilization .trip .trip-details .trip-event img {
	width: 21px;
	top: 5px;
	position: relative;
	margin-right: 5px;
}
.utilization .trip .trip-details .trip-line {
	border-left: 2px solid #e0e0e0;
	margin-top: 2px;
	margin-bottom: -6px;
	height: 21px;
	margin-left: 9px;
}

.drawer-opened {
	width: 70%;
	max-width: calc(100% - 350px);
}

md-backdrop {
	background-color: rgba(33, 33, 33, 0.15) !important;
}

.map-container,
asset-location-map,
.angular-google-map,
.angular-google-map-container {
	width: 100%;
	height: 100%;
}

.diagnostic,
.diagnostic span {
	color: var(--color-theme-negative);
}

.material-icons.diagnostic {
	font-size: inherit;
	position: relative;
	top: 2px;
}

.pad-top {
	padding-top: 20px;
}

.service-record {
	padding-top: 20px;
}
.service-record md-card {
	padding: 5px;
}
.service-record .action-icon {
	padding-right: 10px;
}
.service-record .service-details {
	padding-top: 10px;
}

.white {
	color: var(--color-theme-white);
}

.grey {
	color: var(--color-theme-default);
}

.color-positive {
	color: var(--color-theme-positive);
}

.tabs-card {
	margin-top: 35px;
}

a.md-button.md-primary.md-raised[disabled],
.md-primary.md-raised[disabled] {
	background-color: rgba(0, 0, 0, 0.12) !important;
	color: rgba(0, 0, 0, 0.26) !important;
}

.asset-upload-image {
	background-size: contain;
	background-repeat: no-repeat;
	width: 200px;
	height: 200px;
	margin: auto;
}

.report .jobsite-usage-report .card-head-input:first-of-type {
	padding-bottom: 0;
}

.report .jobsite-usage-report asset-with-usage:not(:last-child) {
	border-bottom: 1px solid var(--color-theme-mute);
}

.report .speeding-details {
	margin: 3px 0 4px;
}

.report .usage-details {
	margin-top: 35px;
}
.report .usage-details .detail {
	margin-top: 10px;
}

.report button.md-button.period-select {
	max-height: 45px;
}

.report .list-item .list-photo {
	width: 35px;
	height: 35px;
	background-size: cover;
	background-position: center;
	border-radius: 100%;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.report .list-item h3 {
	margin-top: 0;
}

.report h2.title {
	font-weight: 400;
	font-size: 21px;
}

.report i.show-detail {
	float: right;
	margin-top: -10px;
	position: relative;
	top: 5px;
}

md-chip.usage {
	color: var(--color-theme-white);
	background-color: #6da2d7;
}

md-chip.on-site {
	color: var(--color-theme-white);
	background-color: var(--color-theme-positive);
}

md-chips .md-chips {
	box-shadow: none;
}

md-list-item md-progress-circular {
	text-align: center;
	margin: auto;
}

.bar-date-chart {
	width: 900px;
	height: 270px;
}

.bar {
	fill: #6da2d7;
}

.on-site {
	fill: var(--color-theme-positive);
}

.axis {
	font-size: 10px;
}

.axis path,
.axis line {
	fill: none;
	stroke: var(--color-theme-black);
	shape-rendering: crispEdges;
}

.x.axis path {
	display: none;
}

@media print {
	body {
		background-color: var(--color-theme-white) !important;
	}
	.app-body,
	.app-content,
	.panel {
		overflow: visible !important;
		height: auto !important;
	}
	md-card {
		border: 1px solid #e0e0e0 !important;
		box-shadow: none !important;
	}
	md-card .list-item {
		page-break-inside: avoid !important;
	}
	.card-head-input h3 {
		margin-top: 75px !important;
	}
	.card-head-input md-select {
		margin: -10px 0 5px !important;
	}
	.card-head-input .md-select-value {
		border-bottom: none !important;
		padding-left: 0 !important;
		font-weight: 600 !important;
		font-size: 18px !important;
	}
	.card-head-input .md-select-icon {
		display: none !important;
	}
	.panel .container {
		padding-bottom: 0;
	}
}

/* Prevents horizontal scrolling should anything overflow the width */
body {
	overflow-x: hidden;
	background-color: var(--color-theme-offwhite);
}

img {
	max-width: 100%;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.full-width {
	width: 100%;
}

.md-fab i {
	margin-top: 15px;
}

.md-success {
	background-color: var(--color-theme-positive) !important;
}

md-progress-linear .md-container {
	background-color: #e4f2fc;
}

md-progress-linear .md-bar {
	background-color: var(--color-theme-primary);
}

.icon-only {
	min-width: 0;
}

.search-location {
	margin:10px;
	font-size:14px;
}

.search-location input {
	font-size:14px;
}

/* MATERIAL ICONS */
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	/* Preferred icon size */
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: 'liga';
}

/* BUTTON STYLING */
.btn-wide {
	width: 100%;
}

/* ALERT STYLING */
.alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
}
.alert.alert-error {
	color: #ab7b7b;
	background-color: #581313;
	border-color: #6f0f1f;
}
.alert.alert-success {
	color: #9bc19b;
	background: #183c09;
	border-color: #214206;
}

.engine-alert {
	display: inline-block;
	margin-top: 5px;
}

.active-row .menu-item {
	background-color: var(--color-theme-primary);
}

.padding {
	padding: 15px;
}

.list-row {
	cursor: pointer;
	transition: all 1s;
}

.button {
	border-radius: 4px;
	border: 0;
	background-color: #6da2d7;
	color: var(--color-theme-white);
}

.red-text {
	color: red;
}

.status-pill {
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	padding: 2px 5px;
	text-transform: uppercase;
}

.status-pill.true {
	background-color: var(--color-theme-positive);
	/* #68F1a9 */
	color: var(--color-theme-white);
}

.status-pill.true:before {
	content: 'enabled';
}

.status-pill.false {
	background-color: var(--color-theme-negative);
	color: var(--color-theme-white);
}

.status-pill.false:before {
	content: 'disabled';
}

@media (max-width: 1024px) {
	.app-content .app-content {
		height: 100%;
	}
	.md-open-menu-container {
		top: 40px !important;
	}
	.panel .tabs .exit.md-button {
		padding: 10px 15px;
	}
	.panel .tabs .tab h4 {
		padding: 7px 25px 3px;
	}
}
