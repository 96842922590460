.layout {
	display: flex;
	flex-flow: row wrap;
	align-self: flex-end;
}

.filterChip {
	margin: 0 0 4px 0;
}

.filterChip > * {
	color: var(--color-theme-primary) !important;
	font-size: 12px;
}
