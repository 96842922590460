.layout {
	display: flex;
}
.input {
	flex: 1;
}
.warning {
	color: var(--color-theme-negative);
	margin: 0.5rem 0;
	font-size: 0.8em;
}
