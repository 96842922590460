.header {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	flex-shrink: 0;
	box-sizing: content-box;
}

.row {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.8em 0;
}

.title > h1 {
	margin: 0;
}

.buttonGroup {
	margin-bottom: 0.8em;
}

.actions {
	display: flex;
	flex-flow: row;
}

.actions > *:not(:last-child) {
	margin-right: 6px;
}
