.nav {
	height: 48px;
	background: var(--color-theme-white);
	border-bottom: 1px solid var(--color-theme-gray-light);
	position: relative;
}
.menuButton {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	position: absolute;
	left: 0.2em;
	top: 0.2em;
	bottom: 0.2em;
	width: 2.6em;
	background: none;
	border: none;
	border-radius: 3px;
}
