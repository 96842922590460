.statusCell {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.statusCellUnderlay {
	position: absolute;
	opacity: 0.1;
	width: 100%;
	height: 100%;
}

.statusDatum {
	display: flex;
	align-items: baseline;
	margin-left: 10px;
}

.statusDatumBullet {
	--status-bullet-size: 8px;
	content: '';
	width: var(--status-bullet-size);
	height: var(--status-bullet-size);
	min-width: var(--status-bullet-size);
	min-height: var(--status-bullet-size);
	border-radius: 50%;
	margin-right: 3px;
}
