.title {
	display: flex;
	justify-content: space-between;
}
.geofence {
	margin: 1em 0;
}
.hr {
	border: none;
	border-bottom: 1px solid var(--color-theme-gray-light);
	margin: 1em 0;
}
.useThisLocation {
	cursor: pointer;
	text-decoration: underline;
}
