.layout {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: space-between;
	margin-top: 18px;
}

.addFilterButton {
	--color: var(--color-theme-primary);
	border-color: var(--color);
	color: var(--color);
	padding-left: 9px;
	padding-right: 9px;
}

.addFilterIcon {
	border-radius: 5%;
	padding: 2px;
}
