html,
body {
	width: 100%;
	height: 100%;
}

.page-container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}

.app-body {
	display: flex;
	justify-content: stretch;
	overflow: hidden;
	height: 100%;
}

.app-view {
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.layout_auth-loading,
.layout_auth-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
