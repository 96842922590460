.loadingContainer {
	position: absolute;
	width: 100%;
	height: 100%;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;
}

.section {
	display: flex;
	flex-flow: column;
}

.section:not(:first-child) {
	margin: 1.2em 0 0;
}

.section h3 {
	margin: 6px 0;
}

.section label {
	font-size: 1em !important;
}

.section * input:disabled {
	background-color: var(--color-theme-offwhite);
}

.headerGroup {
	display: inline-flex;
	align-items: center;
}

.headerGroup * {
	margin: 0;
}

.headerGroup *:first-child {
	margin-right: 3px;
}

.extendedInput,
.extendedInput * input {
	max-width: var(--extended-input-width) !important;
}

.hiddenInput * input,
.hiddenInput * input:disabled {
	border: none;
	padding-left: 0;
	background-color: var(--color-theme-white);
}

.pricingTypeSelector {
	display: block;
	width: 25em;
	margin-bottom: 0.8em;
}

.rowSelectors {
	display: flex;
	flex-flow: row;
	align-items: flex-end;
	margin-bottom: 0.8em;
}

.rowSelectors > * {
	width: 200px;
}

.rowSelectors > :not(:last-child) {
	margin-right: 20px;
}

.sectionRow {
	display: flex;
	flex-direction: row;
	margin-block-start: 1em;
}

.sectionItem {
	margin-right: 1em;
}

.dropdownSelect {
	margin: 0;
	width: auto;
}

.timeBoxOverride
	:global(.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list) {
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pointOfContactName {
	position: relative;
	width: var(--extended-input-width);
}

.pointOfContantNameIcon {
	position: absolute;
	bottom: 8px;
	left: 12px;
	color: var(--color-theme-default);
}

.negative {
	color: var(--color-theme-negative);
}

.positive {
	color: var(--color-theme-positive);
}

.neutral {
	color: var(--color-theme-neutral);
}

.floaterText {
	margin: 10px 5px 10px;
}

.chip {
	margin-left: 0.5em;
	border-radius: 1000px;
}

.chip > * {
	font-size: 0.9em;
}

.chip *:first-child {
	margin-right: unset;
}

.rateDetail {
	margin-right: 0.5em;
	text-align: right;
	width: 89px;
}

.heading {
	font-size: 1em;
	opacity: 0.6;
	margin: 0.3em 0 0.3em 0;
}

.headingDetail {
	margin-top: 0.3em;
	margin-bottom: 0.3em;
	margin-left: 0.3em;
	font-size: 1.3em;
	font-weight: 600;
}

.rateHeadingDetail {
	margin: 1em 0 0.3em 0.3em;
	font-size: 1.3em;
	font-weight: 600;
}

.rateRow {
	margin: 0 0 0.5em 0;
	display: inline-flex;
	width: 100%;
}

.rateSelectButton {
	align-self: center;
	margin-left: 1.5em;
}

.rateSection {
	margin-top: 1.5em;
}

.ratesContainer {
	margin-top: 2em;
}

.ratesContainer > div.rate:not(:last-child) {
	border-bottom: 1px solid var(--color-theme-gray-light);
}

.rateInput {
	width: 175px;
}

.rateType {
	font-weight: bold;
	margin-bottom: 0.5em;
}
