.container {
	display: flex;
	flex-direction: column;
	color: rgb(255, 255, 255);
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.8);
	padding: 5px 10px;
}

.container summary {
	padding: 5px 0;
}

.container pre {
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-word;
	padding: 0 6px;
}

.errorText {
	color: #ff0404;
}
.warningText {
	color: #f3d429;
}
