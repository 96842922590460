.row {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-bottom: 1px solid #eee;
}

.clickable {
	cursor: pointer;
}
.clickable:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
