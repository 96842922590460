address-picker-gmap {
	position: relative;

	input.search-input {
		z-index: 1;
		position: absolute;
		top: 10px;
		left: 190px;
		height: 40px;
		box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
		border: none;
		border-radius: 2px;
		padding: 0 12px;
	}
}
