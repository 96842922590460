.asset-detail {
	& > .grid-layout {
		&.grid-layout-xs,
		&.grid-layout-sm {
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: 1fr;
			grid-template-rows: auto 100vh auto 50vh;

			grid-template-areas: 'header' 'view' 'info' 'map';
		}
		&.grid-layout-lg,
		&.grid-layout-md {
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: 2fr 1fr;
			grid-template-rows: auto 1fr auto;

			grid-template-areas:
				'header map'
				'view map'
				'view info';
		}

		& > .section-header {
			grid-area: header;
		}
		& > .section-info {
			grid-area: info;
		}
		& > .section-view {
			grid-area: view;
		}
		& > .section-map {
			grid-area: map;
		}
	}
}
