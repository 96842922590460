.boolTrue {
	color: var(--color-theme-positive);
}
.boolFalse {
	color: var(--color-theme-negative);
}

.cell svg {
	display: block;
	margin: auto;
}
