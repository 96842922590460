.picker {
	margin-bottom: 1em;
}
.warning {
	background: rgba(242, 201, 76, 0.1);
	border-radius: 3px;
	border-left: 4px solid var(--color-theme-warning);
	padding: 1em;
}
.warning > *:first-child {
	margin-top: 0;
}
.warning > *:last-child {
	margin-bottom: 0;
}
.info {
	margin-right: 0.4em;
}
.form > * {
	margin-bottom: 1em;
}
.inputGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.inputGroup > *:not(:last-child) {
	margin-right: 0.5em;
}
.inputGroup > *:not(:first-child) {
	margin-left: 0.5em;
}
