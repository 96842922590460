.md-button:not(.md-icon-button):not(.md-fab) {
	i {
		position: relative;
		top: 6px;
	}
}

md-input-container label:not(.md-no-float) {
	z-index: initial !important;
}

.md-button-small {
	min-width: 50%;
}

.md-subHeader-noBG {
	background-color: transparent;
}

.section {
	margin-bottom: 25px;
}

.sidenav-form {
	padding: 15px 0;
}

md-progress-linear.progress-bar-thick {
	height: 10px;

	> div,
	.md-bar {
		height: 100%;
	}
}

.input-action,
.input-subtext {
	font-size: 10px;
	font-weight: 600;
	padding: 0 2px;
	text-align: right;
	text-transform: uppercase;
}

.input-action {
	cursor: pointer;
}

md-card {
	color: #616161;
}

.tabs-action {
	margin-bottom: -35px;
	position: relative;
	z-index: 9;
}

.md-block.md-flex-button {
	width: calc(100% - 16px);
}

// TODO: Should these be moved to /scss/main.scss for all apps, not just billing?
.saved {
	color: #68f1a9;
}

.saved-error {
	color: var(--color-theme-negative);
}

.results-list {
	.sort-icon {
		position: relative;
		top: 7px;
		cursor: pointer;
		.material-icons {
			height: 1.5em;
		}
	}
	.odd {
		background-color: $color-ui-gray-100;
	}
}

md-card {
	md-card-content {
		.card-success {
			margin-top: 1em;
		}
	}
}

.number,
.currency {
	text-align: right;
}

.page-title {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	color: #616161;
}

.no-margin {
	margin: 0 !important;
}
.no-margin-bottom {
	margin-bottom: 0 !important;
}
.no-padding {
	padding: 0 !important;
}
.no-error-spacer .md-errors-spacer {
	display: none;
}

.h-spacer {
	padding: 0 6px;
}

.h-spacer-slim {
	padding: 0 4px;
}

.scroll-y {
	overflow-y: auto;
}

.hide-overflow {
	overflow: hidden;
}

.capitalize {
	text-transform: capitalize;
}
