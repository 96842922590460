.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.spacing {
	padding: 10px;
}

.margin {
	margin: 10px;
}

.bold {
	font-weight: bold;
}

.taxChip {
	margin: 10px;
}

.taxChip > * {
	font-size: 1rem;
	padding: 0.2em 0.5em;
}
