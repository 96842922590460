.extendedContainerHeight {
	min-height: 500px;
}
.header {
	display: flex;
	margin: 1rem;
	justify-content: space-between;
	align-items: center;
}
.title {
	margin: 0;
}
.emptyPlaceholder {
	margin: 1rem;
	color: var(--color-theme-gray);
}
