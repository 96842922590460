.body {
	display: table-row-group;
	vertical-align: middle;
	flex-grow: 1;
}

.scrollable {
	overflow-y: auto;
	overflow-x: hidden;
	height: 0;
}
