.layout {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.filterButton {
	margin-right: 8px;
}

.filteredChip {
	margin-right: 8px;
}

.results {
	display: inline-flex;
	align-self: center;
	margin: 0;
}

.results .resultsLoader > * {
	margin: -2px 0 0 4px;
}

.results .resultsLoader > * > * {
	width: 5px;
	height: 5px;
	margin: 0 0.1rem;
	background-color: var(--color-theme-default);
}

.columnRight {
	display: flex;
}
