.container {
	position: relative;
}
.menu {
	display: none;
	width: 100%;
	background: var(--color-theme-white);
	border: 1px solid var(--color-theme-gray-light);
	border-top: none;
	z-index: 100;
	max-height: 20vh;
	overflow-y: auto;
}
.menu.isOpen {
	display: block;
	position: absolute;
}
.list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.option {
	padding: 0.5em 0.8em;
	cursor: pointer;
}
.option:hover {
	background: rgba(0, 0, 0, 0.1);
}
