.info {
	display: grid;
	grid-template-columns: 0.8fr 2fr;
	grid-auto-rows: min-content;
	grid-gap: 1em 1.5em;
}

.info > * {
	margin: 0;
}
