.sectionRow {
	display: flex;
	flex-direction: row;
	margin-block-start: 1em;
}

.sectionItem {
	margin-right: 1em;
}

.sectionButtons {
	align-self: flex-end;
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 2em;
}

.dropdownSelect {
	margin: 0;
	width: auto;
}

.button {
	margin: 0.2em;
	justify-self: flex-end;
}

.rateWarningText {
	margin-bottom: 0.5em;
}

.negative {
	color: var(--color-theme-negative);
}

.negativeBold {
	color: var(--color-theme-negative);
	font-weight: bolder;
}

.rateTypes {
	display: block;
}

.inlineRight {
	justify-content: flex-end;
}
