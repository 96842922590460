.table {
	flex-grow: 1;
	border-bottom: 1px solid #eee;
	margin-top: 0.8em;
	font-size: 12px;
}

.column {
	height: auto;
	vertical-align: middle;
}

.column *,
.row > * {
	white-space: normal;
}

.row {
	text-transform: capitalize;
}

.chipWrapper {
	display: flex;
	justify-content: center;
}

.chip {
	margin-right: 1em;
	padding: 3px;
}

.chip > * {
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	text-align: center;
}

.chipWrapper .chip span {
	white-space: pre-wrap !important;
}

.messageContainer {
	display: flex;
	align-self: center;
	position: relative;
	top: 25%;
}

.messageContainer > * {
	margin: 0 0.5em;
	display: flex;
	align-items: center;
}

.infoIcon {
	width: 0.7rem;
	margin-left: 5px;
	padding-top: 2px;
}
