.filterWrapper {
	width: 100%;
}

.inputExt,
.inputExt * input {
	height: 37px;
	min-height: 37px;
	max-height: 37px;
}
