.links {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.links a {
	display: inline-block;
	cursor: pointer;
	color: var(--color-theme-primary);
	font-size: 0.9rem;
	text-decoration: none;
}
.layout > *:not(:last-child) {
	margin-bottom: 0.8em;
}
