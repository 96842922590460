.layout {
	display: flex;
	flex-direction: column;
}

.map {
	width: 100%;
}
.header {
	padding: 10px;
	margin: 0;
	text-transform: uppercase;
	background: var(--color-theme-offwhite);
}

.list-group {
	border-right: none;
	border-left: none;
}
.list-group-item {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
