.column {
	display: table-cell;
	text-transform: uppercase;
	font-weight: bold;
	border-right: 1px solid #eee;
	text-align: center;
}
.column:last-child {
	border-right: none;
}

.filterInput {
	margin-top: 0.5em;
}

.title {
	padding: 0.7em 0.5em;
	flex-grow: 1;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.headerInner {
	display: flex;
}

.filterButton {
	height: 100%;
}
.filterButton,
.sortButton {
	width: 32px;
	background: none;
	border: none;
	flex-shrink: 0;
	padding: 0.7em 0.2em;
	cursor: pointer;
}
.filterButton:hover,
.sortButton:hover {
	background: rgba(0, 0, 0, 0.05);
}

.filterActive {
	color: #00adee;
}

.hoverable {
	cursor: pointer;
}
.sortable > .headerInner > .title {
	padding-right: 0.2em;
}
.hoverable .title:hover,
.hoverable .title:hover ~ .sortButton {
	background: rgba(0, 0, 0, 0.05);
}

.filterable > .headerInner > .title {
	padding-left: 0.2em;
}
