.rental-agreement {
	md-input-container.message-body {
		margin-top: 0;
		margin-bottom: 0;
		textarea {
			background-color: #e2e1a6;
		}
	}

	.steps {
		margin-left: 25px;
		margin-top: 40px;
	}

	.send-options {
		md-input-container {
			margin-top: 10px;
		}

		.send-option {
			margin: 0;
		}
	}

	.send {
		max-width: 25%;
	}
}
