.drawer {
	md-sidenav {
		max-width: 400px;
		width: 400px;

		&.wide-drawer {
			max-width: 100%;
			width: 90%;
		}
	}

	md-input-container {
		display: block;
	}

	.title {
		padding: 15px;
		background-color: $color-ui-gray-200;
	}

	md-card {
		margin: 0 0 8px 0;
	}

	form {
		padding: 0;
		& > .md-button {
			margin: 0;
		}
	}

	label {
		display: block;
		margin: 16px 0 8px 0;
		font-size: 16px;
	}

	.time-select {
		md-input-container {
			margin-bottom: 0;
		}

		.time-input-container-hours {
		}
		.time-input-container-minutes {
		}
		.time-input-container-ampm {
			margin-top: 0;
		}
	}

	.action-icon {
		color: $color-ui-blue-500;
	}
}

a.drawer-action {
	text-decoration: underline;
}

.drawer-action {
	cursor: pointer;
}
