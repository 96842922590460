.dispatch {
	div.flex-grow,
	md-card.flex-grow {
		flex-grow: 1;
	}

	div.flex-shrink,
	md-card.flex-shrink {
		flex-shrink: 1;
	}

	md-card,
	div {
		&.flex-basis-60 {
			flex-basis: 60%;
		}
		&.flex-basis-400 {
			flex-basis: 400px;
		}
	}

	&.list {
		.cell {
			padding: 0 !important;
			&::before,
			&::after {
				content: '';
				padding: 0 2px;
			}
		}
		.overdue {
			background-color: var(--color-theme-negative);
			color: var(--color-theme-white);
		}
	}

	.loading-bar {
		position: absolute;
	}

	.list-scroll {
		overflow-y: auto;
		overflow-x: hidden;
	}

	.delivery-filters md-progress-circular {
		display: inline-block;
		margin-right: 5px;

		&.inverse svg path {
			stroke: var(--color-theme-white);
		}
	}

	.delivery-status {
		i.material-icons {
			font-size: inherit;
			position: relative;
			top: 1px;
		}

		md-progress-linear {
			margin-top: 5px;

			&[value='0'],
			&[value='25'],
			&[value='50'] {
				.md-container {
					background-color: rgba(255, 29, 35, 0.25);

					.md-bar {
						background-color: var(--color-theme-negative);
					}
				}
			}
			&[value='100'] {
				.md-bar {
					background-color: var(--color-theme-positive);
				}
			}
			.md-bar,
			.md-container {
				height: 7px;
			}
		}
	}

	dispatch-table-row {
		cursor: pointer;
		outline: none;

		.entry {
			font-weight: 500;
			&::after {
				background: transparent;
				content: '';
				height: 100%;
				left: 0;
				width: 100%;
				position: absolute;
				-webkit-transition: background 100ms ease-in, width 100ms ease-out;
				transition: background 100ms ease-in, width 100ms ease-out;
			}
			&:hover {
				&::after {
					background: rgba(0, 0, 0, 0.1);
				}
			}
			&:active {
				&::after {
					background: rgba(0, 0, 0, 0.21);
				}
			}

			.delivery-status {
				i {
					font-size: inherit;
					position: relative;
					top: 1px;
				}
				md-progress-linear {
					margin-top: 5px;

					&[value='0'],
					&[value='25'],
					&[value='50'] {
						.md-container {
							background-color: rgba(255, 29, 35, 0.25);

							.md-bar {
								background-color: var(--color-theme-negative);
							}
						}
					}
					&[value='100'] {
						.md-bar {
							background-color: var(--color-theme-positive);
						}
					}
					.md-bar,
					.md-container {
						height: 7px;
					}
				}
			}
		}
	}
	.action-icon {
		cursor: pointer;
		color: var(--color-theme-white);
		margin-left: 20px;
	}

	.resize-h {
		flex: 0 0 100%;
		max-width: 90%;

		&.resizable {
			padding-right: 10px;

			.rg-right {
				background: var(--color-theme-white);
				color: var(--color-theme-black);
				right: 10px;
			}
		}
	}

	dispatch-table-row,
	dispatch-table-head {
		@for $i from 0 through 20 {
			.flex-#{$i * 5} {
				flex-basis: #{$i * 5}% !important;
			}
		}
	}

	compact-delivery {
		position: relative;

		.compact-delivery-body {
			margin: 0.5rem;
			display: grid;
			grid-template-areas:
				'typename companyname status'
				'date     info        run';
			grid-template-columns: 65px 1fr auto;
			grid-gap: 0.5em;

			.type-name {
				grid-area: typename;
			}

			.company-name {
				grid-area: companyname;
				white-space: normal;

				&::after {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 1px;
					background-color: $color__border--default;
					content: '';
					display: block;
					margin-left: -0.5em;
				}
			}

			.status {
				grid-area: status;
				text-align: right;

				.fa {
					top: 0;
				}
			}

			.scheduled-date {
				grid-area: date;
				align-self: end;
			}

			.asset-info {
				grid-area: info;
			}

			.run {
				grid-area: run;
				align-self: end;
				text-align: right;
			}
		}

		.delivery-type {
			padding: 4px;
		}

		.delivery-info {
			.delivery-info-description,
			.delivery-info-status {
				padding: 8px 12px;
			}
		}

		p {
			margin: 0;
			line-height: 1;
			font-size: 0.8rem;
		}

		& > md-list-item {
			padding: 3px 0;

			&,
			& > .md-button,
			& > .md-button > * {
				&:before {
					min-height: 0;
				}

				min-height: 0;
			}

			& > .md-button.md-no-style {
				padding: 0;
			}
		}
	}

	driver-list {
		.driver-name {
			margin-bottom: 0;
			margin-top: 10px;
			cursor: pointer;
			display: inline-block;
			line-height: 1.8;
		}
	}
}

.empty {
	opacity: 0.21;
}

.legend {
	h3 {
		margin-bottom: 0;
	}
	h4 {
		margin: 0;
	}
	h3,
	h4 {
		span {
			border-radius: 3px;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
			padding: 5px 10px;
		}
	}
}

.dispatch-nav {
	i.material-icons {
		font-size: 17px;
		top: 3px;
	}
}

.rental-agreement {
	.message-body {
		margin-top: 25px;

		textarea {
			background-color: #e2e1a6;
		}
	}
	.steps {
		margin-left: 25px;
		margin-top: 40px;
	}
	.send-options {
		md-input-container {
			margin-top: 10px;
		}
		.send-option {
			margin: 0;
		}
	}
	.send {
		max-width: 25%;
	}
}

.status-pill {
	&.pending {
		color: var(--color-theme-white);
		background-color: #ffaa39;
	}
	&.on-rent {
		color: var(--color-theme-white);
		background-color: #68f1a9;
	}
	&.scheduled {
		color: var(--color-theme-white);
		background-color: #616161;
	}
	&.completed {
		color: var(--color-theme-white);
		background-color: #6da2d7;
	}
}

.sort-icon {
	position: relative;
	top: 7px;
	cursor: pointer;
}

.rental-search {
	md-input-container {
		padding: 2px 2px 0;
	}
	.search-results {
		padding-left: 25px;
	}
}

.drawer {
	md-sidenav {
		width: 30%;
		min-width: 350px;
		max-width: 100%;
		height: 100%;

		&.wide-drawer {
			width: 90%;
		}
		md-input-container {
			display: block;
		}
		.title {
			padding: 15px;
			background-color: var(--color-theme-mute);
		}
		a.drawer-action {
			text-decoration: underline;
		}
		.drawer-action {
			cursor: pointer;
		}
	}
}

.weekhour-row {
	border: 1px solid var(--color-theme-mute);
	border-top: 0;
	border-left: 0;

	.title {
		width: 48px;
		padding: 2px;
		font-size: 14px;
		font-weight: 600;
	}
	&.empty-row {
		max-height: 14px;

		.title {
			font-size: 10px;
			font-weight: 400;
			color: #aaa;
			padding: 0;
			overflow: hidden;
		}
	}
}

.entry-container {
	flex-direction: row;
	align-items: flex-start;

	.entry {
		border-radius: 3px;
		cursor: pointer;
		display: block;
		font-size: 16px;
		font-weight: 500;
		margin: 3px;
		max-width: 100%;
		overflow-x: hidden;
		padding: 5px 7px 7px;
		transition: all 0.2s ease-in-out;
		white-space: nowrap;
		width: 100%;

		i {
			float: right;
			font-size: 16px;
			line-height: 15px;
			position: relative;
			top: 2px;
		}
		p {
			margin: 0;
		}

		.equipment-class {
			font-size: 10px;
		}

		md-list-item {
			font-size: 10px;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 0;
			margin-top: 5px;
			min-height: 0;
			padding: 0;
			&:before {
				min-height: 0;
			}

			i {
				margin-right: 5px;
			}
		}
		md-list-item.done {
			opacity: 0.7;
		}
		.delivery-medium {
			border-top: 1px solid rgba(255, 255, 255, 0.5);
			font-size: 14px;
			padding-top: 5px;

			i {
				float: none;
				margin-right: 3px;
				top: 3px;
			}
		}

		&:hover {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
			transform: scale(1.15);
		}
		&:active {
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.19), 0 4px 4px rgba(0, 0, 0, 0.23);
			transform: scale(1.1);
			transition: all 0.1s ease-in-out;
		}
		&.customer-pickup {
			&:not(.classed) {
				padding-bottom: 3px;
			}
			p {
				margin-top: 1px;
			}
		}

		.entry-title {
			display: block;
			overflow-x: hidden;
			white-space: nowrap;
			font-size: 12px;
		}
		.entry-details {
			width: calc(100% - 24px);
			display: inline-block;
			overflow-x: hidden;
			font-size: 10px;
		}
	}
}
md-dialog {
	md-list.color-legend {
		md-list-item {
			&.color-id-1 {
				background-color: $color-delivery-initial-dropoff-base;
			}
			&.color-id-2 {
				background-color: $color-delivery-initial-dropoff-tomorrow;
			}
			&.color-id-3 {
				background-color: $color-delivery-initial-dropoff-third;
			}
			&.color-id-4 {
				background-color: $color-delivery-initial-dropoff-future;
			}
			&.color-sd {
				background-color: $color-delivery-swap-dropoff;
			}
			&.color-sr {
				background-color: $color-delivery-swap-return;
			}
			&.color-fr {
				background-color: $color-delivery-final-return;
			}
			&.color-m {
				background-color: $color-delivery-move;
			}
			&.color-t {
				background-color: $color-delivery-transport;
			}
			padding: 0;
			margin: 10px;
			border-radius: 3px;
			color: #000;
			p {
				font-size: small;
				border-right: 3px solid var(--color-theme-white);
				padding-top: 20px !important;
				text-align: center;
				width: 1.9em;
				margin: 0;
				padding: 0.4em;
				text-transform: uppercase;
				word-wrap: break-word;
				box-sizing: border-box;
			}
			h3 {
				text-align: center;
				flex: inherit;
				color: #000;
			}
		}
	}
}

md-dialog.md-dialog-fullscreen {
	border-radius: 3px;
}

@media screen and (min-width: 960px) and (max-width: 1112px) {
	.entry-container {
		.entry {
			white-space: normal;
		}
	}
}

@media screen and (max-width: 960px) {
	.dispatch-view {
		width: calc(100% - 114px) !important;
	}
	.md-dialog-container {
		.time-date {
			width: 100%;
		}
		md-dialog.md-dialog-fullscreen {
			min-width: calc(100% - 50px);
			min-height: 0;
		}
	}
}

@media screen and (min-width: 415px) and (max-width: 960px) {
	.md-dialog-container {
		.time-date {
			.display,
			.control {
				width: 50%;
			}
			.md-button {
				padding: 6px 15px;
			}
		}
	}
}

.rapid-rent-icon {
	color: #1a9ac6;
	padding-right: 5px;
}

.rapid-rent-icon > span {
	color: #1a9ac6 !important;
}

.padding-left {
	padding-left: 5px;
}

.padding-right {
	padding-right: 5px;
}
