@import '../../responsive/breakpoints.css';

/*NOTE: remove once app menu is not mounted inside angular*/
react-app-menu {
	overflow: visible;
}

.container {
	height: 100%;
	overflow: visible;

	--backdrop-color: rgba(0, 0, 0, 0.5);
	--item-height: 3em;
	--item-icon-width: 1.5em;
	--item-spacing: 0.5em;
}
.menu {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background: var(--color-theme-offwhite);
}
.menu ::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.logoContainer {
	flex-shrink: 0;
}

.scrollContainer {
	overflow-y: auto;
	border-bottom: 1px solid var(--color-theme-gray-light);
	flex-grow: 1;
}
.scrollContainer > * {
	margin: var(--item-spacing) 0;
}

.backdrop {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 50;
	background: var(--backdrop-color);
}

.bottomButtons {
	margin-top: auto;
	flex-shrink: 0;
	display: flex;
}
.bottomButtons > * {
	display: flex;
	padding: 0;
	align-items: center;
	justify-content: center;
	border: none;
	background: none;
}
.bottomButtons > *:hover {
	color: var(--color-theme-primary);
}

.userCard {
	border: none;
	border-top: var(--color-theme-gray);
	padding: 0.5em;
	display: flex;
	border-bottom: 1px solid var(--color-theme-gray-light);
}
.userAvatar {
	width: 3em;
	height: 3em;
	border-radius: 5em;
	background-color: var(--color-theme-gray-light);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
}
.userInfo {
	padding-left: 0.5rem;
	padding-right: 0.2rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-overflow: ellipsis;
}
.userName {
	font-size: 1rem;
	font-weight: bold;
}
.userEmail {
	font-size: 0.8rem;
}

a.stagingWarning {
	font-size: 0.9rem;
	background-color: var(--color-theme-warning);
	color: var(--color-theme-negative);
	pointer-events: none;
}

@media (--tablet-landscape-and-up) {
	.menu {
		border-right: 1px solid var(--color-theme-gray-light);
		width: 12em;
	}
	.bottomButtons {
		display: flex;
		justify-content: space-between;
	}
	.bottomButtons > * {
		width: var(--item-height);
		height: calc(var(--item-height) / 1.2);
	}

	.logoContainer {
		display: flex;
		padding: 1.5em;
	}
	.logoContainer img {
		height: calc(var(--item-height) / 1.7);
	}

	.container:not(.open) {
		--item-icon-width: 3.5em;
		--item-spacing: 0;

		width: var(--item-icon-width);
	}
	.container:not(.open) .menu {
		width: auto;
	}
	.container:not(.open) .logoContainer {
		height: var(--item-height);
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.container:not(.open) .logoContainer img {
		height: calc(var(--item-height) / 1.5);
	}
	.container:not(.open) .collapseButton {
		width: var(--item-icon-width);
	}
	.container:not(.open) .refreshButton {
		display: none;
	}

	.container:not(.open) .userCard {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.container:not(.open) .userAvatar {
		width: 2.5em;
		height: 2.5em;
	}
	.container:not(.open) .userInfo {
		display: none;
	}
}

@media (--tablet-portrait-and-down) {
	.container {
		--item-spacing: 0;
	}
	.menu {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 11em;
		z-index: 100;
	}
	.container.open .backdrop {
		display: block;
	}
	.bottomButtons {
		display: none;
	}

	.logoContainer {
		padding: 1em 3.5em 1em 1.2em;
	}

	.container:not(.open) {
		display: none;
	}
}
