.delivery-edit-form,
.delivery-create-form {
	md-input-container {
		display: block;
	}

	.row {
		md-input-container {
			display: inline-block;
		}
	}

	md-radio-group {
		margin-left: 10px;
		font-size: 10px;
	}

	.time-select {
		margin-left: 15px;
	}

	md-autocomplete {
		input[name='company'] {
			color: var(--color-theme-black);
		}
	}

	md-select{
		margin-bottom: 10px !important;
	}
}