.link {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: var(--item-height);
	color: var(--color-theme-default);
}
a.link {
	text-decoration: none;
}
.link.clickable {
	cursor: pointer;
}
.label {
	border-radius: 3px;
	margin-left: 0.5em;
	text-align: center;
	transition: opacity 0.25s;
}
.link.clickable:not(.active):hover {
	color: var(--color-theme-primary);
}

.contents {
	border-radius: 3px;
	margin-left: 0.5em;
	transition: opacity 0.25s;
}

.active {
	font-weight: bold;
	color: var(--color-theme-primary);
	background: var(--color-theme-white);
}
.iconContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: content-box;
	border-left: 4px solid transparent;
	height: var(--item-height);
	width: var(--item-icon-width);
	padding-left: 1em;
}
.active .iconContainer {
	border-left-color: var(--color-theme-primary);
}
.icon {
	font-size: 1.2em;
}

.collapsed .iconContainer {
	padding: 0;
	box-sizing: border-box;
	border-right: 4px solid transparent;
}
.collapsed .label {
	display: none;
}
.collapsed .contents {
	display: none;
}
