.layout {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: calc(100vw - 0.8em - 12em - 36px); /* viewportWidth - leftNavWidth - margin/padding of selector */
	min-width: 40vw;
	padding: 12px;
}

.header {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	width: calc(100% + 24px);
	padding: 3px 12px 3px;
	background: var(--color-theme-offwhite);
	border-bottom: 1px solid var(--color-theme-gray-light);
	transform: translate(-12px, -12px);
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.header > h4,
.closeButton > h4 {
	margin: 0;
}

.closeButton {
	padding-left: 9px;
	padding-right: 9px;
	color: var(--color-theme-default);
}

.filterRows {
	width: 100%;
}

.form {
	width: 100%;
}
