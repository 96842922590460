.button {
	border: 1px solid var(--color-theme-mute);
	border-radius: 4px;
	color: var(--color-theme-gray);
	min-width: 34px;
	min-height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.button:hover {
	background: var(--color-theme-mute);
}
