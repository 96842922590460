.row {
	display: flex;
	justify-content: space-between;
	flex-flow: row;
	max-height: 45px;
}
.inputs {
	display: flex;
	flex-flow: row;
	width: 100%;
}
.input {
	display: flex;
	align-items: center;
}
.quantityInput {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 20% !important;
}
.quantityInput > label,
.quantityInput > label > span {
	display: block;
}
.quantityInput > div {
	width: 40%;
}
.quantityLabel {
	display: flex;
	padding-left: 30px;
	align-items: center;
}
.currentInventoryLabel {
	display: flex;
	padding-left: 30px;
	align-items: center;
}
.extendedInput,
.extendedInput * input {
	width: 40% !important;
}
