.button {
	background: var(--color-theme-offwhite);
	border: 1px solid var(--color-theme-gray-light);
	border-radius: 5%;
	min-width: 34px;
	display: block;
	padding: 4px 6px;
	cursor: pointer;
}

.button:hover {
	background: var(--color-theme-mute);
}

.iconBar {
	height: 4px;
	background: var(--color-theme-gray);
	margin: 3px 0;
	border-radius: 25%;
	pointer-events: none;
}

.iconBar:nth-child(2) {
	margin: 3px 3px;
}

.iconBar:nth-child(3) {
	margin: 3px 6px;
}
