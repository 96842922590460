.layout {
	--common-spacing: 16px;
	/* allow drawer content to scroll if it expands beyond window height */
	overflow: scroll;
	height: calc(100vh - 80px);
	/* Keep vertical borders of pickers from being cutoff due to overflow: scroll */
	padding: 0 1px;
}

.pickers {
	border-bottom: 1px solid #ddd;
	padding-bottom: var(--common-spacing);
}

.picker:not(:first-of-type) {
	margin-top: var(--common-spacing);
}

.rateOptions {
	margin: var(--common-spacing) 0;
}

.radioGroup > * {
	display: flex;
	align-items: baseline;
	height: 2em;
}

.radioGroup > *:hover {
	background: rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.radioGroup > *:hover * {
	cursor: pointer;
}

.radioGroup > * input {
	flex-shrink: 0;
}

.radioGroup > * label {
	display: flex;
	flex-grow: 1;
	height: 100%;
	order: 2;
	margin-left: 0.5em;
	font-size: 1rem;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
}

.radioGroup > * > *:first-child {
	margin-right: 0.8em;
}

.radioGroup > * > *:last-child {
	margin-left: 0.4em;
}

.inputs {
	margin-bottom: 2em;
}

.inputs > * {
	margin: 0.5em 0;
}

.inputs > *:first-child {
	margin-top: 0;
}

.inputs > *:last-child {
	margin-bottom: 0;
}

.formActions {
	border-top: 1px solid #ddd;
	padding-top: var(--common-spacing);
}
