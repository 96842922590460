.noPad {
	padding: 0 0 !important;
}

.expand {
	width: 100%;
	height: 100%;
}

.rapidRentsIcon > span {
	color: var(--color-theme-primary) !important;
	width: 20px;
	height: 20px;
	font-size: 18px;
	padding: 0 5px;
}

.trackerCell {
	font-weight: 900;
}
