.table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	display: flex;
	flex-direction: column;
}

.bordered {
	border: 1px solid #eee;
	border-bottom: none;
}
