/* see https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862 */
@custom-media --phone-and-down (max-width: 599px);
@custom-media --phone-only (max-width: 599px);

@custom-media --tablet-portrait-and-down (max-width: 899px);
@custom-media --tablet-portrait-only (min-width: 600px) and (max-width: 899px);
@custom-media --tablet-portrait-and-up (min-width: 600px);

@custom-media --tablet-landscape-and-down (max-width: 1199px);
@custom-media --tablet-landscape-only (min-width: 900px) and (max-width: 1199px);
@custom-media --tablet-landscape-and-up (min-width: 900px);

@custom-media --desktop-and-down (max-width: 1600px);
@custom-media --desktop-only (min-width: 1200px) and (max-width: 1599px);
@custom-media --desktop-and-up (min-width: 1200px);

@custom-media --big-desktop-only (min-width: 1600px);
@custom-media --big-desktop-and-up (min-width: 1600px);
