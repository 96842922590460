company-location-picker {
	width: 100%;
}
delivery-asset-condition {
	.asset-condition-photos-container {
		img {
			margin: 8px;

			&:hover {
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
				transform: scale(1.15);
			}
		}
	}
}
delivery-display-component {
	.delivery-type {
		width: 15px;
	}
}

.open-work-orders {
	background-color: var(--color-theme-negative);
	color: var(--color-theme-white);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	margin-right: 10px;
	margin-left: 10px;
	border-radius: 5px;
}

.swap-container {
	padding: 0 20px;
	margin: 0 0 20px 0;

	.last {
		margin-bottom: 0;
	}
}

.replacement-equipment {
	border-left: 1px solid rgba(0, 0, 0, 0.05);
}
