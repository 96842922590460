.layout {
	--common-spacing: 16px;
	display: grid;
}

.content {
	border-bottom: 1px solid #ddd;
	padding-bottom: var(--common-spacing);
	margin-bottom: var(--common-spacing);
}

.contentHeader {
	margin-bottom: var(--common-spacing);
}

.inputLabel {
	display: flex;
	flex-flow: row;
	align-items: baseline;
}

.subLabel {
	font-size: 10px;
	font-style: italic;
	margin-left: var(--common-spacing);
}

.input {
	margin-bottom: var(--common-spacing);
}

.buttonGroup {
	margin-top: 8px;
	justify-content: flex-end;
}
