.cell {
	display: table-cell;
	border-right: 1px solid #eee;
	padding: 8px 12px;
	vertical-align: middle;
}

.cell:last-child {
	border-right: none;
}

.truncateText {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.preserveNewLines {
	white-space: pre;
}

.noPadding {
	padding: 0;
}
