.layout {
	display: flex;
	flex-direction: column;
}
.layout > * {
	margin: 0.5em 0;
}
.showAdvanced {
	display: inline-block;
	cursor: pointer;
	color: var(--color-theme-primary);
	font-size: 0.9rem;
	text-decoration: none;
}
.advancedOptions {
	overflow: hidden;
	height: auto;
	max-height: 0px;
	margin-bottom: 0;
	margin-top: 0;
}
.advancedOptions > * {
	margin: 0 0 0.5em;
}
.advancedOptions.open {
	overflow: visible;
	max-height: none;
}
