.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.checkbox {
	pointer-events: none;
}
.checkbox label {
	padding: 0;
	position: relative;
}
.checkbox label:before {
	margin: 0;
}
.checkbox label:after {
	left: 6px !important;
	top: 0 !important;
}
