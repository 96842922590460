.layout {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 10px 0;
}

.groupSelector {
	margin-right: 8px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.filterSelect {
	width: 100%;
}

.filterRemoveButton {
	color: var(--color-theme-default);
	padding: 8px 6px;
}
